<template>
  <b-card>
    <b-table
      ref="table"
      :items="getClassifications"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text="No Classification found"
      show-empty
      @sort-changed="onSortChanged"
    >
      <template #cell(id)="{item}">
        <span> # {{ item.id }} </span>
      </template>
      <template #cell(name)="{item}">
        <span> {{ item.name }}</span>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <toggle-status
            :item="item"
            :activate-url="activateUrl"
            :deactivate-url="deactivateUrl"
          />
          <b-dropdown-item
            v-if="$can('update','global-ClassificationController')"
            v-b-modal.edit-modal
            variant="outline-primary"
            @click="getClassificationById(item.id)"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import ToggleStatus from '@/common/components/GeneralSettings/common/ToggleStatus.vue'

export default {
  name: 'ClassificationTable',
  components: {
    ToggleStatus,
  },
  props: {
    getClassifications: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id', label: '#', sortable: true },
        { key: 'name', sortable: true },
        { key: 'status', sortable: true, formatter: v => (v ? 'Activated' : 'Deactivated') },
        { key: 'actions' },
      ],
      activateUrl: '/internalops/classification-activate/',
      deactivateUrl: '/internalops/classification-deactivate/',
    }
  },
  methods: {
    onSortChanged(ctx) {
      this.$emit('onSort', ctx)
    },
    getClassificationById(id) {
      this.$entities.get(`internalops/classifications/${id}`).then(res => {
        this.$emit('update:classification', res.data.data)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
