<template>
  <b-row align-h="center">
    <b-col>

      <div
        v-if="$can('store','global-ClassificationController')"
        class="text-right"
      >
        <b-button
          v-b-modal.add-modal
          variant="primary"
          class="mb-1"
        >
          <span class="text-nowrap">Add Classification</span>
        </b-button>
      </div>

      <b-card header="Search">
        <b-row>
          <b-col>
            <search
              placeholder="Name"
              :search-query.sync="searchQuery"
              @refresh="refreshTable"
            />
          </b-col>
          <b-col
            md="4"
            class="mt-1 mt-md-0"
          >
            <status-filter
              :status.sync="status"
            />
          </b-col>
          <b-col
            md="2"
            class="mt-1 mt-md-0"
          >
            <search-button @refresh="refreshTable" />
          </b-col>
        </b-row>
      </b-card>

      <classifications-table
        ref="parentTable"
        v-bind="{getClassifications,pagination}"
        :classification.sync="classification"
        @onSort="onSortChanged"
      />

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </b-col>
    <div class="d-flex align-items-center justify-content-end m-2">
      <!-- modal Add Classification -->
      <b-modal
        id="add-modal"
        centered
        title="Add Classification"
        hide-footer
      >
        <add-classification
          @refresh="refreshTable"
        />
      </b-modal>
      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit Classification"
        hide-footer
        @hidden="resetClass"
      >
        <edit-classification
          :classification="classification"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import AddClassification from '@/common/components/GeneralSettings/classifications/AddClassification.vue'
import EditClassification from '@/common/components/GeneralSettings/classifications/EditClassification.vue'
import ClassificationsTable from '@/common/components/GeneralSettings/classifications/ClassificationsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import StatusFilter from '@/common/components/common/FormInputs/StatusFilter.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  components: {
    AddClassification,
    EditClassification,
    ClassificationsTable,
    Pagination,
    Search,
    SearchButton,
    StatusFilter,
  },
  data() {
    return {
      classification: {},
      searchQuery: null,
      status: null,
      sortingQuery: null,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    resetClass() {
      this.classification = {}
    },
    onSortChanged(ctx) {
      const lookup = {
        name: 'orderByName',
        status: 'orderByStatus',
      }

      if (ctx.sortBy) { this.sortingQuery = `${lookup[ctx.sortBy]}=${ctx.sortDesc ? 'desc' : 'asc'}` }
    },
    getClassifications() {
      return this.$entities.get(`internalops/classifications?${this.sortingQuery}`, {
        params: {
          name: this.searchQuery,
          page: this.pagination.currentPage,
          status: this.status,
          paginated: true,
        },
      }).then(res => {
        const items = res.data.data

        this.pagination.totalRows = res.data.total
        return items || []
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
